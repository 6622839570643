import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Rx from "rxjs";
import { map, finalize } from "rxjs/operators";
import firebase from 'firebase/compat/app';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  orderHistory = new Rx.BehaviorSubject([]);
  // url: any = 'http://localhost:3000/';
  // url: any = 'https://e1f5-2405-201-4041-c037-d44d-e6c8-9a9a-11d5.ngrok.io/'
  url: any = 'https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api/'
  bannerData: any = [];
  productItem: any = [];
  setLogData: any;
  cartObject: any = {};
  promotionData: any;
  categoryID: Object;

  constructor(
    public http: HttpClient,
    public storage: AngularFireStorage,
    public afs: AngularFirestore
  ) { }

  createeOrder(order): Observable<any> {
    return this.http.post(this.url + 'checkoutVerify', {
      customerName: order.name,
      email: order.email,
      phoneNumber: order.phone,
      amount: order.amount
    }, httpOptions);
  }

  updateOrder(order): Observable<any> {
    return this.http.put(this.url + 'checkoutVerify', {
      razorpay_order_id: order.razorpay_order_id,
      razorpay_payment_id: order.razorpay_payment_id,
      razorpay_signature: order.razorpay_signature
    }, httpOptions);
  }

  async initialize(uid) {
    this.afs.collection('orderHistory', ref => ref.orderBy('timestamp', 'desc').limit(10)).valueChanges().subscribe(async (orderHistory: any) => {
      this.orderHistory.next(orderHistory)
    })
  }

  setProduct(item) {
    this.productItem.push(item);
    console.log("products", this.productItem)
  }

  getProduct() {
    return this.productItem;
  }

  addDeliveryBoy(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'addUpdateDeliveryBoy', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getDeliveryBoys() {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'getDeliveryBoys', {}).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  fetchStoreDetail(formData: any) {
    return this.http.post(this.url + 'storeDetails', formData);
  }

  updateStore(formData: any) {
    return this.http.post(this.url + 'addStore', formData);

  }

  // getProducts(formData: any) {
  //   return this.http.post(this.url + 'getProducts', formData);
  // }

  getProducts(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'getProducts', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  orderWise(postData: any): Observable<any> {
    return this.http.post(this.url + 'datewiseOrder', postData)
  }

  getRegistrationNumbers(postData: any): Observable<any> {
    return this.http.post(this.url + 'dailyRegistrations', postData)
  }

  getProductWiseOrder(postData: any): Observable<any> {
    return this.http.post(this.url + 'productwiseOrders', postData)
  }

  storeList(formData: any) {
    return this.http.post(this.url + 'citywiseStores', formData);
  }
  getOrderStatus(postData: any) {
    return this.http.post(this.url + 'statuswiseData', postData)
  }
  getStoreList(postData: any) {
    return this.http.post(this.url + 'storewiseOrders', postData)
  }
  getAmountWiseOrders(postData: any) {
    return this.http.post(this.url + 'amountwiseData', postData)
  }

  fetchProductDetail(postData: any) {
    return this.http.post(this.url + 'fetchProductDetail', postData)
  }

  variants(postData: any) {
    return this.http.post(this.url + 'variantMaster', postData)
  }

  addProduct(productData: any) {
    return this.http.post(this.url + 'addProduct', productData)
  }

  //deleteProduct
  deleteProduct(posttData: any) {
    return this.http.post(this.url + 'deleteProduct', posttData)
  }

  //Send to SAP

  sendtoSAP(posttData: any) {
    return this.http.post(this.url + 'toggleSAP', posttData)
  }



  uploadImage(event) {
    return new Promise(async (resolve, reject) => {
      var n = Date.now();
      const file = event.target.files[0];
      console.log(file)
      const filePath = `productImages/${n}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(`productImages/${n}`, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(resp => {
            resolve(resp);
            // console.log(resp);
          });
          // this.loadingProvider.dismiss();
        })
      )
        .subscribe()
    })


    // task
    //   .snapshotChanges()
    //   .pipe(
    //     finalize(() => {
    //       let downloadURL = fileRef.getDownloadURL();
    //       downloadURL.subscribe(url => {
    //         console.log(url);
    //       });
    //     })
    //   )
    //   .subscribe(url => {
    //     if (url) {
    //       console.log(url);
    //     }
    //   });
  }
  bannerList(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'fetchBannerPromoMobileMiddle1', formData).subscribe(resp => {
        this.bannerData = resp;
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  ////Fetch banner list 
  addPromotion(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'fetchBannerPromoMobileMiddle1', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  ///update and add banners

  uploadBanner(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'updateBanner', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  SendPromotion(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'createPromotions', formData).subscribe(resp => {       
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  createOrder(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'createadminOrder', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  checkVerify(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'checkoutVerify', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  
  PromotionList(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'getPromotions', formData).subscribe(resp => {
        this.promotionData = resp;
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  WhatsappPromotionList(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.url + 'fetchwhatsappPromotion', formData).subscribe(resp => {
        this.promotionData = resp;
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  SMSPromotion(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'sendbulkpromotions', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  CustomerSupport(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'fetchSupport', formData).subscribe(resp => {
        this.promotionData = resp;
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  fetchCategory(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'fetchcateg', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  fetchSubCategory(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'fetchsubcat', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  updateCategory(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'addCateg', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  // coupon start
  createCouponCode(data:any) {
    return this.http.post(this.url + 'createCoupon',data)
  }
  fetchCoupon(data:any) {
    return this.http.post(this.url + 'fetchCoupon',data)
  }
// end
  // MySubscription start

  fetchMySubPro(data:any) {
    return this.http.post(this.url + 'fetchSubscription',data)
  }
  initiateSubspro(data:any) {
    return this.http.post(this.url + 'initiateSubscription',data)
  }
  // end
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  updateSubCategory(formData) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.url + 'addsubcat', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
//movisio
plmProductDetails(): Observable<any> {
  return this.http.get(this.url+'fetchAllPlmDetails')
}

inventoryDetails(): Observable<any> {
  return this.http.get(this.url+'fetchInventoryDetails ')
}
createInventory(data: any): Observable<any> {
  return this.http.post(this.url + 'createInventory', data)
}
createproduts(data: any): Observable<any> {
  return this.http.post(this.url + 'createPlmProduct', data)
}
sellerRegister(data: any): Observable<any> {
  return this.http.post(this.url + 'sellerRegister', data)
}
storeAssign(data: any): Observable<any> {
  return this.http.post(this.url + 'storeAssign', data)
}
deliveryAssign(data: any): Observable<any> {
  return this.http.post(this.url + 'addPinCode', data)
}
fetchSeller(): Observable<any> {
  return this.http.get(this.url +'fetchSeller')
}
attendanceDetailsByDate(data): Observable<any> {
  return this.http.post(this.url +'attendanceDetailsByDate',data)
}
fetchAsignPersion(): Observable<any> {
  return this.http.get(this.url +'fetchStoreAssign')
}
massUpload(data: any): Observable<any> {
  return this.http.post(this.url + 'uploadPlmExcel', data)
}
productNumber(): Observable<any> {
  return this.http.get(this.url+'fetchApprovedPlm')
}
}
