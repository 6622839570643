import { Injectable } from '@angular/core';
import { LoadingController, ToastController, } from '@ionic/angular';


@Injectable()
export class LoadingService {

  loading: any;
  loadingPresent: boolean = false;

  constructor(public toastCtrl: ToastController,
    public loadingCtrl: LoadingController) {

    //  console.log('Hello LoadingProvider Provider');

  }

  // For present loading
  // presentLoading() {
  //  this.loading = this.loadingCtrl.create({
  //    content: 'Please wait...'
  //  });
  //  this.loading.present();
  // }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    // console.log([year, month, day].join('-'))
    return [year, month, day].join('/');
  }
  async presentLoading(message) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      translucent: true,
      spinner: 'crescent',
      duration: 10000
    });
    this.loadingPresent = true;
    await this.loading.present();

    // const { role, data } = await this.loading.onDidDismiss();

    // console.log('Loading dismissed!');
  }
  async presentLoadingg() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'circles',
      duration: 2000,
      message: 'Please wait...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
    });
    return await this.loading.present();
  }
  async presentLoadingInfinite(message) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      translucent: true,
      spinner: 'crescent'
    });
    this.loadingPresent = true;
    await this.loading.present();

    // const { role, data } = await this.loading.onDidDismiss();

    // console.log('Loading dismissed!');
  }


  dismiss() {
    this.loadingPresent = true;
    this.loading.dismiss();
  }
  async presentToast(infoMessage:string) {
    const toast = await this.toastCtrl.create({
      message: infoMessage,
      duration: 2000,
      //position: 'top',
    });
    toast.present();
  }

  showToast(type: 'success' | 'error', message: string) {
    const cssClass = type === 'error' ? 'toast-error' : 'toast-success';
  
    this.toastCtrl.create({
      message: message,
      duration: 2000,
      cssClass: cssClass,
    }).then(toast => toast.present());
  }
  // Toast message
  // presentToast(text) {
  //  let toast = this.toastCtrl.create({
  //    message: text,
  //    duration: 3000,
  //    position: 'top'
  //  });
  //  toast.present();
  // }

}
