import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { StoreService } from './services/store.service';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userId : any;
  firebaseUser: any;
  appInitialized: boolean = false;
  uid: any;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storeService: StoreService,
    public afAuth: AngularFireAuth,
    public splashScreen: SplashScreen,
    public platform: Platform,
    public statusBar: StatusBar,
    public router: Router
  ) {
   
  
    this.platform.ready().then(() => {


      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHexString('#000000');
      // this.statusBar.backgroundColorByHexString('#f6f6f6');
      // this.statusBar.backgroundColorByHexString('#ea989a');
      // this.statusBar.styleBlackTranslucent();
      // this.authService.initialize();

      this.userService.currentUser.subscribe((user: any) => {
        // console.log(user)
        if (this.firebaseUser && user && Object.keys(user).length > 0 && !this.appInitialized) {

          this.splashScreen.hide();
          this.appInitialized = true;

          // this.router.navigateByUrl('tabs/home');
          this.router.navigateByUrl('tabs/employee-onboarding-home');

          // this.postService.initialize();
          // if (user.userType == 'student') {
          //   this.employerService.initialize();
          //   this.studentService.initialize(user.uid);
          //   this.router.navigateByUrl('studentTabs/home');
          // }
          // if (user.userType == 'counsellor') {
          //   this.router.navigateByUrl('counsellorTabs/home');
          // }
          // if (user.userType == 'unischool') {
          //   this.router.navigateByUrl('unischoolTabs/home', {replaceUrl: true});
          // }
          // if (user.userType == 'employer') {
          //   this.employerService.initialize();
          //   this.router.navigateByUrl('employerTabs/home');
          // }
          // localStorage.setItem("uid", user.uid);
          // this.checkAuth(user);
        }
      });

      this.afAuth.onAuthStateChanged(firebaseUser => {
        // this.splashScreen.hide();
        // let url = this.router.url
        this.firebaseUser = firebaseUser
        if (firebaseUser && firebaseUser.uid) {
        localStorage.setItem('userId', JSON.stringify(firebaseUser.uid))
        console.log("userId", firebaseUser.uid)
        console.log("firebaseUser uid", firebaseUser.uid)
          this.userService.initialize(firebaseUser.uid);
          this.storeService.initialize(firebaseUser.uid);
          // this.router.nav.igateByUrl('studentHome');
        } else {

          this.appInitialized = false;
          this.router.navigateByUrl('login');
        }

      }, error => {
        // console.log(error)
      })

    })



    // this.platform.ready().then(() => {

    //   this.afAuth.onAuthStateChanged(firebaseUser => {
    //     console.log("firebaseUser ", firebaseUser)
    //     if(firebaseUser){
    //       this.userService.uid = firebaseUser.uid;
    //       this.userService.initialize(firebaseUser.uid);
    //       if(firebaseUser.displayName){
    //         this.router.navigate(['/tabs/feeds'])
    //       }
    //       else{
    //         this.router.navigate(['/registration'])
    //       }
    //       console.log(this.userService.uid);
    //     }
    //   }, error => {
        
    //   })

    // })
  }

  
   
}
